import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateCheckPromo = {
  checkPromo: { data: null, loading: false, error: null },
};

export const checkPromo = createAsyncThunk('tariff/CheckPromo', async (promo, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .get(`${process.env.REACT_APP_SERVER_API}/check-promo`, {
      params: {
        promo: promo?.toUpperCase(),
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerCheckPromo = {
  [checkPromo.pending]: (state) => {
    state.checkPromo.loading = true;
    state.checkPromo.error = null;
    state.checkPromo.data = null;
  },
  [checkPromo.fulfilled]: (state, action) => {
    state.checkPromo.loading = false;
    state.checkPromo.data = action.payload;
    state.checkPromo.error = null;
  },
  [checkPromo.rejected]: (state, action) => {
    state.checkPromo.loading = false;
    state.checkPromo.error = action.payload;
    state.checkPromo.data = null;
  },
};
