import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateCheckPartnerCode = {
  checkPartnerCode: { data: null, loading: false, error: null },
};

export const checkPartnerCode = createAsyncThunk('tariff/CheckPartnerCode', async (code, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .get(`${process.env.REACT_APP_SERVER_API}/check-partner-code`, {
      params: {
        code,
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerCheckPartnerCode = {
  [checkPartnerCode.pending]: (state) => {
    state.checkPartnerCode.loading = true;
    state.checkPartnerCode.error = null;
    state.checkPartnerCode.data = null;
  },
  [checkPartnerCode.fulfilled]: (state, action) => {
    state.checkPartnerCode.loading = false;
    state.checkPartnerCode.data = action.payload;
    state.checkPartnerCode.error = null;
  },
  [checkPartnerCode.rejected]: (state, action) => {
    state.checkPartnerCode.loading = false;
    state.checkPartnerCode.error = action.payload;
    state.checkPartnerCode.data = null;
  },
};
